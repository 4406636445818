<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 mt-2">
        <b-row>
          <b-col cols="12" md="8">
            <ancient-prox-filter
              :search-query.sync="searchQuery"
            />
          </b-col>
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <crud-button :onClickRefresh="refetchData" :onClickAddNew="addItem" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-right">
            <!-- Update -->
            <feather-icon
              @click="$router.push({ name: 'manage-ancient-prox-save', params: { id: data.item.id } })"
              icon="EditIcon"
              class="cursor-pointer text-primary"
              size="16"
            />
            <!-- Remove -->
            <feather-icon
              @click="deleteItem(data.item.id)"
              icon="Trash2Icon"
              class="cursor-pointer mx-1 text-danger"
              size="16"
            />
          </div>
        </template>

      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <pagination-component
          :per-page="perPage"
          :current-page.sync="currentPage"
          :total-items="totalItems"
          :meta="dataMeta"
          @page-changed="refetchData"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
// Alert Toast
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import useAncientProxList from './useAncientProxList'
import AncientProxFilter from './AncientProxFilter.vue'
import PaginationComponent from '../../pagination/PaginationComponent.vue'

export default {
  components: {
    AncientProxFilter,
    PaginationComponent,
  },
  methods: {
    addItem() {
      this.$router.push({ name: 'manage-ancient-prox-save', params: { id: 0 } });
    },
    deleteItem(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('ancientProxManagement/removeAncientProx', { id })
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: this.$t(response.message) },
              })
              this.refetchData()
            } else {
              AlertService.error(this, this.$t(response.message))
            }
          });
      })
    },
  },
  setup() {
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,

      refetchData,
    } = useAncientProxList()

    return {

      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,

      refetchData,
    }
  },
}
</script>
